import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import {Link} from "gatsby";

const CancellationPolicy = () => {
  return (
    <>
      <PageWrapper >
        <SEO title="Cancellation Policy"></SEO>
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Cancellation policy
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Last updated: August 14, 2020
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="10">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-8">
                      We want satisfied customers, not hostages. That’s why we make it easy for you to cancel your account directly in all of our apps — no phone calls required, no questions asked.
                    </p>
                    <p className="gr-text-8">
                      Our legal responsibility is to account owners, which means we cannot cancel an account at the request of anyone else. If for whatever reason you no longer know who the account owner is, contact us. We will gladly reach out to any current account owners at the email addresses we have on file.
                    </p>
                  </div>

                  <div className="single-block mb-11">
                    <h3 className="gr-text-6 font-weight-bold pb-3">What happens when you cancel?</h3>
                    <p className="gr-text-8">
                      You won’t be able to access your account once you cancel, so make sure you download everything you want to keep beforehand.
                    </p>
                    <p className="gr-text-8">
                      We’ll permanently delete your account data within 30 days from our servers and logs, and within 60 days from our backups. Retrieving data for a single account from a backup isn’t possible, so if you change your mind you’ll need to do it within the first 30 days. Data can’t be recovered once it has been permanently deleted.
                    </p>
                    <p className="gr-text-8">
                      We won’t bill you again once you cancel. We don’t automatically prorate any unused time you may have left but if you haven’t used your account in months or just started a new billing cycle, contact us for a <Link to="/policies/refund">fair refund</Link>. We’ll treat you right.
                    </p>
                  </div>

                  <div className="single-block mb-11">
                    <h3 className="gr-text-6 font-weight-bold pb-3">Camelo-initiated cancellations</h3>
                    <p className="gr-text-8">
                      We may cancel accounts if they have been inactive for an extended period:
                    </p>
                    <ul className="gr-text-8">
                      <li>30 days after a trial has expired without being upgraded</li>
                      <li>For frozen accounts: 180 days after being frozen due to billing failures</li>
                      <li>For free accounts: after 180 days of inactivity</li>
                    </ul>
                    <p className="gr-text-8">
                      We also retain the right to suspend or terminate accounts for any reason at any time, as outlined in our <Link to="/policies/terms-of-service">Terms of Service</Link>. In practice, this generally means we will cancel your account without notice if we have evidence that you are using our products to engage in <Link to="/policies/abuse">abusive behavior</Link>.
                    </p>
                  </div>
                </div>
                <p>
                  <Link
                    to="/legal"
                    className="btn-link with-icon-left gr-text-blue gr-text-7 font-weight-bold mt-11"
                  >
                    <i className="icon icon-tail-left font-weight-bold"></i>
                      View All Policies
                    </Link>
                </p>
                <p className="text-muted gr-opacity-4 text-right pt-12"><small>Adapted from the Camelo open-source policies / CC BY 4.0</small></p>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default CancellationPolicy;
